import React from "react";
import "./App.css";
import {
  Typography,
  createTheme,
  ThemeProvider,
  Theme,
  PaletteOptions,
} from "@mui/material";

import mainImg from "./images/DPP-154.jpg";
import WeddingMenu from "./components/WeddingMenu";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

interface CustomPaletteOptions extends PaletteOptions {
  green?: {
    dark: string;
    light: string;
  };
}

export const spacing = "30px";
export const boxPadding = "50px 0px 50px 0px";

export const darkGreen = "#193130";
export const lightGreen = "#E8EAEA";
export const darkBlue = "#072a49";
export const almostWhite = "#fdfdfd";

export const defaultHeight = "35vh";
const datumSvatby = "7. září 2024";

const breakpoints = {
  values: {
    mobile: 0,
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
  },
};

function App() {
  const theme: Theme = createTheme({
    breakpoints: breakpoints,
    components: {
      MuiTypography: {
        styleOverrides: {
          h4: {
            [`& @media screen (max-width: ${breakpoints.values.mobile}px)`]: {
              fontSize: "2.3em",
            },
            [`& @media screen (max-width: ${breakpoints.values.tablet}px)`]: {
              fontSize: "3em",
            },
          },
        },
      },
    },
    palette: {
      green: {
        dark: "#193130",
        light: "#E8EAEA",
      },
    } as CustomPaletteOptions,
    typography: {
      h1: {
        fontFamily: "Great Vibes",
      },
      h3: {
        color: "#475a59",
        fontFamily: "Montserrat",
      },
      h4: {
        [`& @media screen (max-width: ${breakpoints.values.mobile}px)`]: {
          fontSize: "2.3em",
        },
        [`& @media screen (max-width: ${breakpoints.values.tablet}px)`]: {
          fontSize: "3em",
        },
      },
    },
  });

  theme.typography.body1.marginBottom = theme.spacing(2);
  theme.typography.h1.marginBottom = theme.spacing(2);

  return (
    <ThemeProvider theme={theme}>
      <div className="app-container">
        <div className="header-container">
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                mobile: "3.3em",
                tablet: "4em",
                laptop: "5em",
                desktop: "6em",
              },
              pt: {
                mobile: "25px",
                tablet: "0px",
              },
              mb: {
                mobile: "0px",
                tablet: "8px",
                laptop: "16px",
              },
            }}
          >
            Kateřina & Michael
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                mobile: "1.3em",
                tablet: "1.7em",
                laptop: "2em",
              },
              mb: 0,
            }}
          >
            se vzali {datumSvatby}
          </Typography>
        </div>

        <div className="main-img-container">
          <img src={mainImg} alt="main img" />
        </div>

        <WeddingMenu></WeddingMenu>
      </div>
    </ThemeProvider>
  );
}

export default App;
