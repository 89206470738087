import React from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import { boxPadding } from "../App";

const WeddingMenu = () => {
  return (
    <div
      style={{
        padding: boxPadding,
        //backgroundColor: darkGreen,
        //width: "100%",
        //color: almostWhite,
      }}
    >
      <Typography variant="h4">Odkazy na foto a video:</Typography>
      <Box
        className="text-box-container"
        paddingTop={"15px"}
        paddingBottom={"35px"}
      >
        <Typography variant="h6" component="div">
          <Link
            href="https://photos.app.goo.gl/HhuLY5aYmXKt39qo8"
            target="_blank"
            rel="noopener"
            style={{ color: "inherit", textDecoration: "underline" }} // Inherit color from Typography
          >
            Foto fotografka
          </Link>
        </Typography>
        <Typography variant="h6" component="div">
          <Link
            href="https://eu.zonerama.com/Aenorr/Album/12003654"
            target="_blank"
            rel="noopener"
            style={{ color: "inherit", textDecoration: "underline" }} // Inherit color from Typography
          >
            Foto Marek Kohut
          </Link>
        </Typography>
        <Typography variant="h6" component="div">
          <Link
            href="https://www.youtube.com/watch?v=awp0FRYSYA0"
            target="_blank"
            rel="noopener"
            style={{ color: "inherit", textDecoration: "underline" }} // Inherit color from Typography
          >
            Video Marek Kohut
          </Link>
        </Typography>
        <Typography variant="h6" component="div">
          <Link
            href="https://photos.app.goo.gl/q7PxhFG65iL9F3nC9"
            target="_blank"
            rel="noopener"
            style={{ color: "inherit", textDecoration: "underline" }} // Inherit color from Typography
          >
            Foto instax
          </Link>
        </Typography>
      </Box>

      <Typography variant="h4">Sem nahrajte své fotky a videa:</Typography>
      <Box className="text-box-container" paddingTop={"15px"}>
        <Typography variant="h6" component="div">
          <Link
            href="https://photos.app.goo.gl/BTXHd6rVCL1fCE9G7"
            target="_blank"
            rel="noopener"
            style={{ color: "inherit", textDecoration: "underline" }} // Inherit color from Typography
          >
            Ostatní
          </Link>
        </Typography>
      </Box>
    </div>
  );
};

export default WeddingMenu;
